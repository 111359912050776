<template>
  <v-app>
    <!--  Application Bar-->
    <slot name="appBar">
      <app-bar></app-bar>
    </slot>

    <!-- Application Left Drawer-->
    <slot name="navigationLeftDrawer"></slot>

    <!-- Application Main -->
    <v-main>
      <slot name="main">
        <v-container fluid>
          <router-view></router-view>
        </v-container>
      </slot>
    </v-main>

    <!-- Navigation Right Drawer -->
    <slot name="navigationRightDrawer"></slot>

    <!-- Application Footer -->
    <slot name="footer">
      <app-footer></app-footer>
    </slot>

    <!-- Bottom Navigation -->
    <slot name="bottomNavigation"></slot>
  </v-app>
</template>

<script>
import { menuItemSettingsMixin } from "@/mixins/shared/base/settings/menuItemSettingsMixin";

export default {
  name: "AppLayout",
  mixins: [menuItemSettingsMixin],
  components: {
    AppBar: () => import("@/components/shared/core/bars/AppBar"),
    AppFooter: () => import("@/components/shared/core/footers/AppFooter")
  }
};
</script>
